import React from "react";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import ChangePassword from './ChangePassword'
import Users from './Users'
import Sessions from './Sessions'
import CreateNewUser from './CreateNewUser'

export default class SettingsMain extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        loading:false,
        error:false,
        showPage:""
      }
    }

componentDidMount() {
    //
  }

  setShowPage = (pageName) => {
    this.setState({showPage:pageName})
  }

  setButtonDisabled = (currentRole, roleRequired) => {
    if(currentRole.toLowerCase() === roleRequired.toLowerCase()){
      return "btn btn-outline-dark"
    }
    else{
      return "btn btn-outline-dark disabled"
    }
  }

  setVisibleBasedOnRole = (requiredRole) => {
    let role = this.props.userRole;
    if(role !== undefined){
      role = role.toLowerCase();
      requiredRole = requiredRole.toLowerCase();
      if(requiredRole === "administrator"){
        if(role === "administrator"){
          return "visible";
        }
        else{
          return "invisible";
        }
      }
      else if(requiredRole === "operator"){
        if(role === "administrator" || role === "operator"){
          return "visible";
        }
        else{
          return "invisible";
        }
      }
      else{
        return "visible";
      }
    }
    else{
      return "invisible";
    }

  }

  render() {

    let backgroundColor = this.props.colorTheme() === "light" ? "#f2f2f2" : "#323232";
    let textColor = this.props.colorTheme() === "light" ? "black" : "rgb(200,200,200)";

    let pageSelector = <></>;
    let pageBackBtn = <></>;
    if(this.state.showPage){
      pageBackBtn = <button style={{color:textColor, borderColor:textColor}} type="button" onClick={() => this.setShowPage("")} className="btn btn-outline-secondary mx-2">Back</button>;
    }
    if(this.state.showPage === "changePassword"){
      pageSelector = <ChangePassword colorTheme={this.props.colorTheme} auth_token={this.props.auth_token}/>
    }
    else if(this.state.showPage === "users"){
      pageSelector = <Users colorTheme={this.props.colorTheme} userName={this.props.userName} auth_token={this.props.auth_token}/>
    }
    else if(this.state.showPage === "createNewUser"){
      pageSelector = <CreateNewUser colorTheme={this.props.colorTheme} auth_token={this.props.auth_token}/>
    }
    else if(this.state.showPage === "sessions"){
      pageSelector = <Sessions colorTheme={this.props.colorTheme} auth_token={this.props.auth_token}/>
    }
    else{
      pageSelector = <div className="container">
                      <div className="row">
                        <div className="col-4 text-center my-3">
                          <button type="button" style={{width:"100%",height:"100%", color: textColor, borderColor: textColor}} onClick={() => this.setShowPage("changePassword")} className="btn btn-outline-secondary btn-sm">Change Password</button>
                        </div>
                        <div className="col-4 text-center my-3">
                          <button type="button" style={{width:"100%",height:"100%", color: textColor, borderColor: textColor}} onClick={() => this.setShowPage("users")} className={"btn btn-outline-secondary btn-sm " + this.setVisibleBasedOnRole("administrator")}>Users</button>
                        </div>
                        <div className="col-4 text-center my-3">
                          <button type="button" style={{width:"100%",height:"100%", color: textColor, borderColor: textColor}} onClick={() => this.setShowPage("createNewUser")} className={"btn btn-outline-secondary btn-sm " + this.setVisibleBasedOnRole("administrator")}>Create New User</button>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4 text-center my-3">
                          <button type="button" style={{width:"100%",height:"100%", color: textColor, borderColor: textColor}} onClick={() => this.setShowPage("sessions")} className={"btn btn-outline-secondary btn-sm " + this.setVisibleBasedOnRole("administrator")}>Sessions</button>
                        </div>
                        <div className="col-4 text-center my-3"></div>
                        <div className="col-4 text-center my-3"></div>
                      </div>
                  </div>;
    }


    return(

      <div style={{width:"100%", backgroundColor: backgroundColor}}>

        <div className="container">

        <div className="text-center">
          <h1 style={{color:textColor}}> Settings </h1>
        </div>

        <div>
          {pageBackBtn}
        </div>

        <div>
          {pageSelector}
        </div>

        </div>

      </div>

    )
  }

}
