import React from "react";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import {getServerUrl} from '../ApplicationNetworking'

export default class RestoreBackupConfigModal extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        loading:false,
        error:false,
        modalWidth:300,
        modalHeight:400,
        newValue:"",
        responseText:"",
        valueChangeSuccess:false,
        positionOffsetX:window.innerWidth/2,
        positionOffsetY:window.innerHeight/2,
        isDragging:false,
        dragCursorOffset:null,
        configFile:"",
        restoreSucessful:false
      }
  }

  componentDidMount() {
    this.setModalDimensions();
    window.addEventListener('resize', this.setModalDimensions);
  }

  componentDidUpdate(previousProps) {
    // Reset the window when its closed so it will be centered at the screen once its opens again.
    if(previousProps.show && !this.props.show){
      this.resetWindowToCenter()
    }
  }

 setModalDimensions = () => {
    let width = "400px";
    if(window.innerWidth < 767){
      width = window.innerWidth*0.6; // 80 vw
    }
    let height = "300px";
    if(window.innerHeight < 767){
      height = window.innerHeight*0.65; // 80 vh
    }
    this.setState({ modalWidth: width, modalHeight:height});
    this.resetWindowToCenter()
  }

  resetWindowToCenter = () => {
    this.setState({positionOffsetX:window.innerWidth/2, positionOffsetY:window.innerHeight/2});
  }

dragWindow = (event) => {
    event.preventDefault();
    let cursorOffsetX = 0;
    let cursorOffsetY = 0;
    if(!this.state.dragCursorOffset){
      this.setState({dragCursorOffset:[event.clientX - this.state.positionOffsetX,event.clientY - this.state.positionOffsetY]});
      cursorOffsetX = event.clientX - this.state.positionOffsetX;
      cursorOffsetY = event.clientY - this.state.positionOffsetY;
    }
    else{
      cursorOffsetX = this.state.dragCursorOffset[0];
      cursorOffsetY = this.state.dragCursorOffset[1];  
    }
    if(event.clientX > 0 &&  event.clientY > 0){ 

      let newPosX = event.clientX - cursorOffsetX;
      // Limit X position inside window.
      if(newPosX > window.innerWidth - this.state.modalWidth/2){
        newPosX = window.innerWidth - this.state.modalWidth/2;
      }
      else if (newPosX < this.state.modalWidth/2){
        newPosX = this.state.modalWidth/2;
      }
      let newPosY = event.clientY - cursorOffsetY
      // Limit Y position inside window.
      if(newPosY > window.innerHeight - this.state.modalHeight/2){
        newPosY = window.innerHeight - this.state.modalHeight/2;
      }
      else if (newPosY < this.state.modalHeight/2){
        newPosY = this.state.modalHeight/2;
      }
      this.setState({positionOffsetX:newPosX, positionOffsetY:newPosY, isDragging:true});
    }
  }

  resetIsDragging = () =>{
    this.setState({isDragging:false, dragCursorOffset:null});
  }

  closeModal = () => {
   this.props.close(false);
   this.setState({responseText:""});
  }

  handleFileChange = (event) => {
    const fileReader = new FileReader();
    fileReader.readAsText(event.target.files[0], "UTF-8");
    fileReader.onload = event => {
      try{
        let jsonData = JSON.parse(event.target.result);
        console.log(jsonData);
        this.setState({configFile:jsonData});
      }
      catch(error){
        console.log(error);
        this.setState({responseText:error.toString()});
      }
    };
  }

  confirmRestoreFromBackup = () => {
    let confirmDelete = window.confirm("Are you sure to restore alarms? ALL CURRENT ALARMS WILL BE DELETED");
    if(confirmDelete){
      this.restoreFromBackupFile();
    }
  }

  restoreFromBackupFile = () => {
    if(this.state.configFile){
      this.setState({ isLoading:true, error:false, responseText: ""});
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(this.state.configFile)};
        let url = getServerUrl() + this.props.restAPIURL + "?auth_token=" + this.props.auth_token;
        fetch(url, requestOptions)
         .then((response) => {
          if(response.ok){
            return response.json()
          }
          return Promise.reject(response);
         })
         .then((data) => {
            this.props.update();
            if(data.includes("successful")){
              this.setState({responseText: data, restoreSucessful:true});
            }
            else{
              this.setState({responseText: data});
            }
         })
         .catch((response) => {
          this.setState({isLoading: false, error:true, responseText: "Error restoring backup!"});
        });
    }
  }


  render() {

    let backgroundColor = this.props.colorTheme() === "light" ? "#f2f2f2" : "#323232";
    let inputFieldBackgroundColor = this.props.colorTheme() === "light" ? "#ffffff" : "#505050";
    let textColor = this.props.colorTheme() === "light" ? "black" : "rgb(200,200,200)";

    let modalStyle = {
        position:"absolute",
        height: this.state.modalHeight,
        width: this.state.modalWidth,
        left: this.state.positionOffsetX,
        top: this.state.positionOffsetY,
        transform: "translate(-50%, -50%)",
        backgroundColor: backgroundColor,
        border: this.props.colorTheme() === "light" ? "5px solid #053d57" : "2px solid #f2f2f2",
        borderRadius: "10px",
        zIndex:"2",
        padding:"5px"
    };

    let restoreAlarmConfigButton = <></>;
    if(this.state.configFile){
      restoreAlarmConfigButton = <button type="button" style={{color:textColor, borderColor:textColor}} className="btn btn-outline-secondary btn-block mb-4" onClick={() => this.confirmRestoreFromBackup()}>Restore from backup</button>;
    }

    let responseText = <></>;
    if(this.state.restoreSucessful){
      responseText = <p className="text-success">{this.state.responseText}</p>
    }
    else{
      responseText = <p className="text-danger">{this.state.responseText}</p>
    }

    if(this.props.show){
      return(
      <div style={modalStyle}>

        <div className="container">

          <div draggable="true" style={{cursor:"move"}} onDrag={(event) => this.dragWindow(event)} onDragEnd={this.resetIsDragging} className="row">
            <div className="col-sm-12">
              <button className="close-button" style={{backgroundColor:backgroundColor,float:"right", borderRadius:"5px", borderColor:textColor}} onClick={() => this.closeModal()}>
                <span style={{color:this.props.colorTheme() === "light" ? "black" : "white"}} aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>

          <div className="row my-1">
            <div className="col-sm-12">
              <div className="text-left">
                <h5 style={{color:textColor}}>{this.props.title}</h5>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="text-center">
              {responseText}
            </div>
          </div>


          <div className="w-100">
          <div className="form-outline">
            <label style={{color:textColor}} className="form-label" htmlFor="restoreAlarmConfigModalFileInput">Alarm configuration file:</label>
            <input style={{backgroundColor:inputFieldBackgroundColor, color:textColor}} type="file" id="restoreAlarmConfigModalFileInput" onChange={(event) => this.handleFileChange(event)} className="form-control" value={this.state.mqttTopic}/>
          </div>

          <div className="text-center my-3">
            {restoreAlarmConfigButton}
          </div>
          
          </div>
          
        </div> {/* container */}

      </div> 
    )}
    else{
      return(
        <div></div>
      )
    }
  }

}
