import React from "react";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import {getServerUrl} from '../ApplicationNetworking'

export default class CreateNewUser extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        loading:false,
        error:false,
        responseText:"",
        username:"",
        password:"",
        role:"",
        userCreated:false,
        showPassword:false
      }
    }

  componentDidMount() {
    this.setState({username:"", password:""});
  }

  handlePasswordChange = (event) => {
    this.setState({password:event.target.value});
  }

  handleUsernameChange = (event) => {
    this.setState({username:event.target.value});
  }

  handleRoleSelect = (event) => {
    this.setState({role:event.target.value});
  }

  toggleShowPassword = () => {
    this.setState({showPassword:!this.state.showPassword});
  }

 createNewUser = () => {
   if(this.state.username.length > 0 && this.state.role.length > 0 && this.state.username.length > 0){
      this.setState({ isLoading:true, error:false, responseText: ""});
      let url = getServerUrl() + "createuser?auth_token=" + this.props.auth_token + "&username=" +this.state.username + "&role=" +this.state.role + "&password=" +this.state.password; 
       fetch(url)
       .then((response) => {
        if(response.ok){
          return response.json()
        }
        return Promise.reject(response);
       })
       .then((data) => {
          if(data === "Created new user!"){
            this.setState({userCreated:true});
          }
          this.setState({responseText: data});
       })
       .catch((response) => {
        let responseStatusText = "";
        if(response.statusText === undefined){
          responseStatusText = "No response from server!"
        }
        else{
          responseStatusText = response.statusText;
        }
        this.setState({isLoading: false, error:true, responseText: responseStatusText});
      });
   }
   else{
    this.setState({isLoading: false, error:true, responseText: "Passwords does not match!"});
   }
   
  }

  render() {

    let textColor = this.props.colorTheme() === "light" ? "black" : "rgb(200,200,200)";
    let inputFieldBackgroundColor = this.props.colorTheme() === "light" ? "#ffffff" : "#505050";

    let createUserButton = <></>;
    if(this.state.username.length > 0 && this.state.password.length > 0 && this.state.role.length > 0){
      createUserButton = <button style={{color:textColor, borderColor:textColor}} type="button" className="btn btn-outline-secondary btn-block mb-4" onClick={() => this.createNewUser()}>Create</button>;
    }

    let responseText = <></>;
    if(this.state.userCreated){
      responseText = <p className="text-success">{this.state.responseText}</p>
    }
    else{
      responseText = <p className="text-danger">{this.state.responseText}</p>
    }
    let createUserForm = <></>;
    if(!this.state.userCreated){
      createUserForm = <div><form id="createNewUserForm" autoComplete="off">
                        <div className="form-outline mb-4">
                          <label style={{color:textColor}} className="form-label" htmlFor="createNewUserFormUsername">Username:</label>
                          <input autoComplete="off" style={{backgroundColor:inputFieldBackgroundColor, color:textColor}} type="text" id="createNewUserFormUsername" onChange={(event) => this.handleUsernameChange(event)} className="form-control" value={this.state.username}/>
                        </div>

                        <div className="form-outline mb-4">
                          <label style={{color:textColor}} className="form-label" htmlFor="createNewUserFormPassword">Password:</label>
                          <input autoComplete="off" style={{backgroundColor:inputFieldBackgroundColor, color:textColor}} type="password" onChange={(event) => this.handlePasswordChange(event)} className="form-control" value={this.state.password}/>
                        </div>

                        <select style={{backgroundColor: inputFieldBackgroundColor, color:textColor}} value={this.state.role} onChange={(event) => this.handleRoleSelect(event)} className="form-select" aria-label="Default select example">
                          <option>Select role:</option>
                          <option value="viewer">Viewer</option>
                          <option value="operator">Operator</option>
                          <option value="administrator">Administrator</option>
                        </select>

                        <div className="text-center my-3">
                          {createUserButton}
                        </div>
                    </form></div>;
    }


    return(
      <div className="container">  

          <div className="row">
            <div className="col-sm-12 text-center">
                <h5 style={{color:textColor}}> Create New User </h5>
            </div>
          </div>

          <div className="row justify-content-md-center">
              <div className="col-md-auto">

                  <div className="row my-2">
                    <div className="text-center">
                      {responseText}
                    </div>
                  </div>

                  {createUserForm}

              </div>
            </div>     

      </div>
    )
  }

}
