import React from "react";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import {getServerUrl} from '../ApplicationNetworking'

export default class CreateNewAlarmModal extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        loading:false,
        error:false,
        positionOffsetX:window.innerWidth/2,
        positionOffsetY:window.innerHeight/2,
        modalWidth:0,
        modalHeight:0,
        isDragging:false,
        dragOffsets:null,
        mqttTopic:"",
        alarmName:"",
        systemName:"",
        alarmLabel:"",
        alarmPriority:"",
        alarmValue:1.0,
        alarmValueBitNumber:0,
        responseText:"",
        alarmCreated:false,
        bitValueSelection:false
      }
  }

  componentDidMount() {
    this.setModalDimensions();
    window.addEventListener('resize', this.setModalDimensions);
  }

  componentDidUpdate(previousProps) {
    // Reset the window when its closed so it will be centered at the screen once its opens again.
    if(previousProps.show && !this.props.show){
      this.resetWindowToCenter()
    }
  }

  setModalDimensions = () => {
    let width = "380px";
    if(window.innerWidth < 767){
      width = window.innerWidth*0.8;
    }
    let height = "650px";
    if(window.innerHeight < 767){
      height = window.innerHeight*0.8;
    }
    this.setState({ modalWidth: width, modalHeight:height});
    this.resetWindowToCenter();
  }

  resetWindowToCenter = () => {
    this.setState({positionOffsetX:window.innerWidth/2, positionOffsetY:window.innerHeight/2});
  }

  closeModal = () => {
    this.props.close(false);
    this.setState({alarmCreated:false, mqttTopic:"",alarmName:"",systemName:"",alarmLabel:"",alarmPriority:"",alarmValue:1.0,alarmValueBitNumber:"",responseText:"",bitValueSelection:false});
  }


  dragWindow = (event) => {
    event.preventDefault();
    let cursorOffsetX = 0;
    let cursorOffsetY = 0;
    if(!this.state.dragCursorOffset){
      this.setState({dragCursorOffset:[event.clientX - this.state.positionOffsetX,event.clientY - this.state.positionOffsetY]});
      cursorOffsetX = event.clientX - this.state.positionOffsetX;
      cursorOffsetY = event.clientY - this.state.positionOffsetY;
    }
    else{
      cursorOffsetX = this.state.dragCursorOffset[0];
      cursorOffsetY = this.state.dragCursorOffset[1];  
    }
    if(event.clientX > 0 &&  event.clientY > 0){ 

      let newPosX = event.clientX - cursorOffsetX;
      // Limit X position inside window.
      if(newPosX > window.innerWidth - this.state.modalWidth/2){
        newPosX = window.innerWidth - this.state.modalWidth/2;
      }
      else if (newPosX < this.state.modalWidth/2){
        newPosX = this.state.modalWidth/2;
      }
      let newPosY = event.clientY - cursorOffsetY
      // Limit Y position inside window.
      if(newPosY > window.innerHeight - this.state.modalHeight/2){
        newPosY = window.innerHeight - this.state.modalHeight/2;
      }
      else if (newPosY < this.state.modalHeight/2){
        newPosY = this.state.modalHeight/2;
      }
      this.setState({positionOffsetX:newPosX, positionOffsetY:newPosY, isDragging:true});
    }
  }

  resetIsDragging = () =>{
    this.setState({isDragging:false, dragCursorOffset:null});
  }

  createNewAlarm = () => {
    let params = { 
        "mqtt_topic": this.state.mqttTopic,
        "alarm_name":this.state.alarmName,
        "system_name":this.state.systemName,
        "alarm_label":this.state.alarmLabel,
        "alarm_priority":this.state.alarmPriority,
        "alarm_value":this.state.alarmValue
        };
    if(this.state.bitValueSelection){
      params["alarm_value_bit_number"] = this.state.alarmValueBitNumber;
    }
    this.setState({ isLoading:true, error:false, responseText: ""});
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(params)};
    let url = getServerUrl() + "createalarm?auth_token=" + this.props.auth_token;
    fetch(url, requestOptions)
     .then((response) => {
      if(response.ok){
        return response.json()
      }
      return Promise.reject(response);
     })
     .then((data) => {
        this.props.update();
        this.setState({responseText: data,alarmCreated:true});
     })
     .catch((response) => {
      this.setState({isLoading: false, error:true, responseText: "Error updating value!"});
    });
  }

  handleTopicChange = (event) => {
    event.target.value = event.target.value.replace("#","")
    event.target.value = event.target.value.replace("+","")
    this.setState({mqttTopic:event.target.value});
  }

  handleAlarmNameChange = (event) => {
    this.setState({alarmName:event.target.value});
  }

  handleSystemNameChange = (event) => {
    let systemName = event.target.value;
    systemName = systemName.toUpperCase()
    this.setState({systemName:systemName});
  }

  handleAlarmLabelChange = (event) => {
    this.setState({alarmLabel:event.target.value});
  }

  handleAlarmPriorityChange = (event) => {
    this.setState({alarmPriority:event.target.value});
  }

  handleAlarmValueChange = (event) => {
    this.setState({alarmValue:event.target.value});
  }

  handleAlarmValueBtnChange = (value) => {
    if(value){
      value = 1.0;
    }
    else{
      value = 0.0;
    }
    this.setState({alarmValue:value});
  }

  handleAlarmValueBitNumberChange = (event) => {
    let value = parseInt(event.target.value);
    if(event.target.value === ""){
      value = 0;
    }
    if(value >= 0 && value < 64){
      this.setState({alarmValueBitNumber:value});
    } 
  }

  setBitValueSelection = (value) => {
    this.setState({bitValueSelection:value});
  }

  render() {

    let backgroundColor = this.props.colorTheme() === "light" ? "#f2f2f2" : "#323232";
    let inputFieldBackgroundColor = this.props.colorTheme() === "light" ? "#ffffff" : "#505050";
    let textColor = this.props.colorTheme() === "light" ? "black" : "rgb(200,200,200)";

    let modalStyle = {
        position:"absolute",
        height: this.state.modalHeight,
        width: this.state.modalWidth,
        left: this.state.positionOffsetX,
        top: this.state.positionOffsetY,
        transform: "translate(-50%, -50%)",
        backgroundColor: backgroundColor,
        border: this.props.colorTheme() === "light" ? "5px solid #053d57" : "2px solid #f2f2f2",
        borderRadius: "10px",
        zIndex:"1",
        padding:"5px"
    };

    let createNewAlarmButton = <></>;
    if(this.state.mqttTopic.length > 0 && this.state.alarmName.length > 0 && this.state.systemName.length > 0 && this.state.alarmLabel.length > 0 &&
       this.state.alarmPriority.length > 0 && !isNaN(this.state.alarmValue) && (!this.state.bitValueSelection || (this.state.bitValueSelection && !isNaN(this.state.alarmValueBitNumber)))){
      createNewAlarmButton = <button type="button" style={{color:textColor, borderColor:textColor}} className="btn btn-outline-secondary btn-block mb-4" onClick={() => this.createNewAlarm()}>Create</button>;
    }

    let responseText = <></>;
    if(this.state.alarmCreated){
      responseText = <p className="text-success">{this.state.responseText}</p>
    }
    else{
      responseText = <p className="text-danger">{this.state.responseText}</p>
    }


    let alarmValueDiv = <></>;
    if(this.state.bitValueSelection){
      alarmValueDiv = <div>
                        <div className="row my-1">
                        <label style={{color:textColor}} className="form-label" htmlFor="createNewAlarmValue">Alarm value:</label>
                          <div className="col">
                            
                            <input style={{backgroundColor:inputFieldBackgroundColor, color:textColor}} autoComplete="off" placeholder="Bit number" step="1" type="number" id="createNewAlarmValue" onChange={(event) => this.handleAlarmValueBitNumberChange(event)} className="form-control" value={this.state.alarmValueBitNumber}/>
                          </div>
                          <div className="col">
                            <div className="btn-group">
                              <button style={{color:textColor, borderColor:textColor}} className={this.state.alarmValue ? "btn btn-success w-100" : "btn btn-outline-secondary w-100"} onClick={() => this.handleAlarmValueBtnChange(true)}>true</button>
                              <button style={{color:textColor, borderColor:textColor}} className={this.state.alarmValue ? "btn btn-outline-secondary w-100" : "btn btn-success w-100"} onClick={() => this.handleAlarmValueBtnChange(false)}>false</button>
                            </div>
                          </div>
                        </div>
                      </div>
    }
    else{
      alarmValueDiv = <div className="form-outline">
                        <label style={{color:textColor}} className="form-label" htmlFor="createNewAlarmValue">Alarm value:</label>
                        <input style={{backgroundColor:inputFieldBackgroundColor, color:textColor}} placeholder="numeric value" type="number" id="createNewAlarmValue" onChange={(event) => this.handleAlarmValueChange(event)} className="form-control" value={this.state.alarmValue}/>
                      </div>
    }

    if(this.props.show){
      return(
      <div>

      <div style={modalStyle}>

        <div className="container">

          <div draggable="true" style={{cursor:"move"}} onDrag={(event) => this.dragWindow(event)} onDragEnd={this.resetIsDragging} className="row">
            <div className="col-sm-12">
              <button className="close-button" style={{backgroundColor:backgroundColor,float:"right", borderRadius:"5px", borderColor:textColor}} onClick={() => this.closeModal()}>
                <span style={{color:this.props.colorTheme() === "light" ? "black" : "white"}} aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>



        <div className="row">
          <div className="col-sm-12 text-center">
              <h5 style={{color:textColor}}> Create new alarm </h5>
          </div>
        </div>      

        <div className="row">
          <div className="text-center">
            {responseText}
          </div>
        </div>

        <div className="w-100">
               <div className="form-outline">
                  <label style={{color:textColor}} className="form-label" htmlFor="createNewAlarmTopic">MQTT topic:</label>
                  <input style={{backgroundColor:inputFieldBackgroundColor, color:textColor}} autoComplete="off" placeholder="aqs-xx-xx/analog/pt1/alarm_register" type="text" id="createNewAlarmTopic" onChange={(event) => this.handleTopicChange(event)} className="form-control" value={this.state.mqttTopic}/>
                </div>

                <div className="form-outline">
                  <label style={{color:textColor}} className="form-label" htmlFor="createNewAlarmAlarmName">Alarm name:</label>
                  <input style={{backgroundColor:inputFieldBackgroundColor, color:textColor}} autoComplete="off" placeholder="High high alarm" type="text" id="createNewAlarmAlarmName" onChange={(event) => this.handleAlarmNameChange(event)} className="form-control" value={this.state.alarmName}/>
                </div>


                <div className="form-outline">
                  <label style={{color:textColor}} className="form-label" htmlFor="createNewAlarmLabelName">Alarm label:</label>
                  <input style={{backgroundColor:inputFieldBackgroundColor, color:textColor}} autoComplete="off" placeholder="Water inlet pipe, pressure transmitter (PT1)" type="text" id="createNewAlarmLabelName" onChange={(event) => this.handleAlarmLabelChange(event)} className="form-control" value={this.state.alarmLabel}/>
                </div>

                <div className="form-outline">
                  <label style={{color:textColor}} className="form-label" htmlFor="createNewAlarmSystemName">System name:</label>
                  <input style={{backgroundColor:inputFieldBackgroundColor, color:textColor}} autoComplete="off" placeholder="aqs-xx-xx" type="text" id="createNewAlarmSystemName" onChange={(event) => this.handleSystemNameChange(event)} className="form-control" value={this.state.systemName}/>
                </div>

                <label style={{color:textColor}} className="form-label" htmlFor="createNewAlarmAlarmPriority">Alarm priority:</label>
                <select style={{backgroundColor: inputFieldBackgroundColor, color:textColor}} id="createNewAlarmAlarmPriority" value={this.state.alarmPriority} onChange={(event) => this.handleAlarmPriorityChange(event)} className="form-select">
                  <option>Select priority:</option>
                  <option value="low">Low</option>
                  <option default value="medium">Medium</option>
                  <option value="High">High</option>
                  <option value="Critical">Critical</option>
                </select>

                {/*<label className="form-label" htmlFor="createNewAlarmValueSelection">Value selection:</label>  */}     
                <div id="createNewAlarmValueSelection">
                  <p style={{color:textColor}}> Value selection: </p>
                  <div className="text-center">
                    <div className="btn-group">
                      <button style={{color:textColor, borderColor:textColor}} className={this.state.bitValueSelection ? "btn btn-outline-secondary w-100" : "btn btn-success w-100"} onClick={() => this.setBitValueSelection(false)}>Numeric</button>
                      <button style={{color:textColor, borderColor:textColor}} className={this.state.bitValueSelection ? "btn btn-success w-100" : "btn btn-outline-secondary w-100"} onClick={() => this.setBitValueSelection(true)}>Bit</button>
                    </div>
                  </div>
                </div>

                {alarmValueDiv}
                

                <div className="text-center my-3">
                  {createNewAlarmButton}
                </div>
            
          </div>
    


          
        </div> {/* container */}

      </div> 
      </div>
    )}
    else{
      return(
        <div></div>
      )
    }
  }

}
