import React from "react";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faLockOpen, faPencil } from '@fortawesome/free-solid-svg-icons'

import NumericEntryFieldModal from './NumericEntryFieldModal'

import {getServerUrl} from '../ApplicationNetworking'

export default class AquasenseVerificationModal extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        loading:false,
        error:false,
        positionOffsetX:window.innerWidth/2,
        positionOffsetY:window.innerHeight/2,
        modalWidth:0,
        modalHeight:0,
        isDragging:false,
        showNumericFieldModal:false,
        numericFieldModalData:{},
        dragOffsets:null,
        editUnlocked:false,
        showRunTimeInfo:false
      }
  }

  componentDidMount() {
    this.setModalDimensions();
    window.addEventListener('resize', this.setModalDimensions);
  }

  componentDidUpdate(previousProps) {
    // Reset the window when its closed so it will be centered at the screen once its opens again.
    if(previousProps.show && !this.props.show){
      this.resetWindowToCenter();
      this.setState({editUnlocked:false});
    }
  }

  setModalDimensions = () => {
    let width = "380px";
    if(window.innerWidth < 480){
      width = parseInt(window.innerWidth*0.8) + "px"; // 80vw
    }
    let height = "480px";
    if(window.innerHeight < 480){
      height = parseInt(window.innerHeight*0.8) + "px"; // 80vh
      this.setState({showRunTimeInfo:false});
    }
    else{
      this.setState({showRunTimeInfo:true});
    }
    this.setState({ modalWidth: width, modalHeight:height});
    this.resetWindowToCenter();
  }

  resetWindowToCenter = () => {
    this.setState({positionOffsetX:window.innerWidth/2, positionOffsetY:window.innerHeight/2});
  }

  closeModal = () => {
    this.props.close(false);
    this.setState({showNumericFieldModal:false, commandSent:false});
  }

  openNumericFieldModal = (jsonData) => {
    if(this.state.editUnlocked){
      this.setState({showNumericFieldModal:true, numericFieldModalData:jsonData});
    }
  }

  closeNumericFieldModal = () => {
    this.setState({showNumericFieldModal:false});
  }

  dragWindow = (event) => {
    event.preventDefault();
    let cursorOffsetX = 0;
    let cursorOffsetY = 0;
    if(!this.state.dragCursorOffset){
      this.setState({dragCursorOffset:[event.clientX - this.state.positionOffsetX,event.clientY - this.state.positionOffsetY]});
      cursorOffsetX = event.clientX - this.state.positionOffsetX;
      cursorOffsetY = event.clientY - this.state.positionOffsetY;
    }
    else{
      cursorOffsetX = this.state.dragCursorOffset[0];
      cursorOffsetY = this.state.dragCursorOffset[1];  
    }
    if(event.clientX > 0 &&  event.clientY > 0){ 

      let newPosX = event.clientX - cursorOffsetX;
      // Limit X position inside window.
      if(newPosX > window.innerWidth - this.state.modalWidth/2){
        newPosX = window.innerWidth - this.state.modalWidth/2;
      }
      else if (newPosX < this.state.modalWidth/2){
        newPosX = this.state.modalWidth/2;
      }
      let newPosY = event.clientY - cursorOffsetY
      // Limit Y position inside window.
      if(newPosY > window.innerHeight - this.state.modalHeight/2){
        newPosY = window.innerHeight - this.state.modalHeight/2;
      }
      else if (newPosY < this.state.modalHeight/2){
        newPosY = this.state.modalHeight/2;
      }
      this.setState({positionOffsetX:newPosX, positionOffsetY:newPosY, isDragging:true});
    }
  }

  toggleEditUnlock = () => {
    this.setState({editUnlocked:!this.state.editUnlocked});
  }

  resetIsDragging = () =>{
    this.setState({isDragging:false, dragCursorOffset:null});
  }

  insertEvent = (eventDescription) => {
    let objectName = this.props.customProps.objectName;
    let objectDescription = this.props.customProps.objectDescription;
    let systemName = this.props.unitName;
    let userName = this.props.customProps.userName;
    if(eventDescription && objectName && objectDescription && systemName && userName){
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          "event_object_name": objectName,
          "event_object_description":objectDescription,
          "event_description":eventDescription,
          "system_name":systemName.toUpperCase(),
          "operator_name":userName,
          "event_from_operator":true})};
      let url = getServerUrl() + "insertevent?auth_token=" + this.props.auth_token;
      fetch(url, requestOptions)
       .then((response) => {
        if(response.ok){
          return response.json()
        }
        return Promise.reject(response);
       })
       .then((data) => {
          // pass
       })
       .catch((response) => {
        this.setState({isLoading: false, error:true});
      });
    }
  }

  setVerificationEnabled = () => {
    let topic = this.props.dataPath + "/enabled/set"
    this.sendMQTT(topic, 1);
    this.insertEvent("Enabled");  
  }

  setVerificationDisable = () => {
    let topic = this.props.dataPath + "/enabled/set"
    this.sendMQTT(topic, 0);
    this.insertEvent("Disabled");  
  }

  setVerificationManualStart = () => {
    let topic = this.props.dataPath + "/manual_start/set"
    this.sendMQTT(topic, 1);
    this.insertEvent("Manual start");
  }

  sendMQTT = (topic, value) => {
    this.setState({isLoading: true, error:false, responseText: "", commandSent:true});
     const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          "topic": topic,
          "payload":{"value":value}})
    };
    let url = getServerUrl() + "sendvalue?auth_token=" + this.props.auth_token;
    fetch(url, requestOptions)
     .then((response) => {
      if(response.ok){
        return response.json()
      }
      return Promise.reject(response);
     })
     .then((data) => {
        this.setState({isLoading: false, error:false, responseText: ""});
     })
     .catch((response) => {
      this.setState({isLoading: false, error:true, responseText: "Error updating value!"});
    });
    
  }


  convertMinutesToHHMM = (totalMinutes) => {
    let retValue = "";
    if(!isNaN(totalMinutes)){
      let minutes = totalMinutes % 60;
      let hours = (totalMinutes - minutes)/60
      if(hours > 0){
        if(hours < 10){
          retValue = "0" + hours + " h "
        }
        else{
          retValue = hours + " h "
        }
        
      }
      if(minutes > 0){
        if(minutes < 10){
          retValue += "0" + minutes + " m "
        }
        else{
          retValue += minutes + " m "
        }
      }
    }
    return retValue
  }



  render() {

    let backgroundColor = this.props.colorTheme() === "light" ? "#f2f2f2" : "#323232";
    let textColor = this.props.colorTheme() === "light" ? "black" : "rgb(200,200,200)";

    let modalStyle = {
        position:"absolute",
        height: this.state.modalHeight,
        width: this.state.modalWidth,
        left: this.state.positionOffsetX,
        top: this.state.positionOffsetY,
        transform: "translate(-50%, -50%)",
        backgroundColor: backgroundColor,
        border: this.props.colorTheme() === "light" ? "5px solid #053d57" : "2px solid #f2f2f2",
        borderRadius: "10px",
        zIndex:"1",
        padding:"5px"
    };

    let styleParameters = {
          fontSize:"calc(50% + 0.6vw)",
          color:textColor,
          marginLeft:"15px"
    };


    let data = {};
    if(this.props.data !== undefined){
      data = this.props.data;
    }

    let enableBtnClass = "btn btn-outline-secondary w-100";
    let disableBtnClass = "btn btn-outline-secondary w-100";
    let verificationEnabled = NaN;

    if(data.hasOwnProperty(this.props.dataPath + "/enabled")){
      verificationEnabled = data[this.props.dataPath + "/enabled"].value;
      if(verificationEnabled){
        enableBtnClass = "btn btn-success w-100";
      }
      else{
        disableBtnClass = "btn btn-success w-100";
      }
    }

    let editLockSymbol = this.state.editUnlocked ? faLockOpen : faLock;

    /* Length */
    let lengthPv = NaN;
    let lengthPvDiv = <div className="border rounded">
                          <i style={styleParameters} >Length: No data! </i>
                         </div>;
    if(data.hasOwnProperty(this.props.dataPath + "/length")){
        lengthPv = parseFloat(data[this.props.dataPath + "/length"].value.toFixed(2));
        lengthPvDiv = <div className="border rounded">
                            <i style={styleParameters} >Length: {lengthPv} min.
                              <FontAwesomeIcon className="float-end" style={{cursor:"pointer", marginRight:"10px"}} icon={faPencil} onClick={() => this.openNumericFieldModal(
                              {"dataPath":this.props.dataPath+"/length/set","currentValue":lengthPv,"unitOfMeasurement":"min.", 
                              "objectName":this.props.customProps.objectName, "objectDescription":"Verification length", "min":0, "max":120,
                              "unitName":this.props.unitName, "userName":this.props.customProps.userName, "stepIncrement":1.0})}/>
                            </i>
                          </div>;
    }

    /* Interval days */
    let internvalDaysPv = NaN;
    let internvalDaysDiv = <div className="border rounded">
                          <i style={styleParameters} >Interval days: No data! </i>
                         </div>;
    if(data.hasOwnProperty(this.props.dataPath + "/interval_days")){
        internvalDaysPv = parseFloat(data[this.props.dataPath + "/interval_days"].value.toFixed(2));
        internvalDaysDiv = <div className="border rounded">
                            <i style={styleParameters} >Interval days: {internvalDaysPv} days
                              <FontAwesomeIcon className="float-end" style={{cursor:"pointer", marginRight:"10px"}} icon={faPencil} onClick={() => this.openNumericFieldModal(
                              {"dataPath":this.props.dataPath+"/interval_days/set","currentValue":internvalDaysPv,"unitOfMeasurement":"days", 
                              "objectName":this.props.customProps.objectName, "objectDescription":"Verification interval days", "min":1, "max":7,
                              "unitName":this.props.unitName, "userName":this.props.customProps.userName, "stepIncrement":1.0})}/>
                            </i>
                          </div>;
    }

    /* Clock hour */
    let clockHourPv = NaN;
    let clockHourPvDiv = <div className="border rounded">
                          <i style={styleParameters} >Clock hour: No data! </i>
                         </div>;
    if(data.hasOwnProperty(this.props.dataPath + "/clock_hour")){
        clockHourPv = parseFloat(data[this.props.dataPath + "/clock_hour"].value.toFixed(2));
        clockHourPvDiv = <div className="border rounded">
                            <i style={styleParameters} >Clock hour: {clockHourPv} hrs. UTC
                              <FontAwesomeIcon className="float-end" style={{cursor:"pointer", marginRight:"10px"}} icon={faPencil} onClick={() => this.openNumericFieldModal(
                              {"dataPath":this.props.dataPath+"/clock_hour/set","currentValue":clockHourPv,"unitOfMeasurement":"hrs. (UTC)", 
                              "objectName":this.props.customProps.objectName, "objectDescription":"Verification clock hour (UTC)", "min":0, "max":23,
                              "unitName":this.props.unitName, "userName":this.props.customProps.userName, "stepIncrement":1.0})}/>
                            </i>
                          </div>;
    }

    /* Clock minute */
    let clockMinutePv = NaN;
    let clockMinutePvDiv = <div className="border rounded">
                          <i style={styleParameters} >Clock minute: No data! </i>
                         </div>;
    if(data.hasOwnProperty(this.props.dataPath + "/clock_minutes")){
        clockMinutePv = parseFloat(data[this.props.dataPath + "/clock_minutes"].value.toFixed(2));
        clockMinutePvDiv = <div className="border rounded">
                            <i style={styleParameters} >Clock minute: {clockMinutePv} min. UTC
                              <FontAwesomeIcon className="float-end" style={{cursor:"pointer", marginRight:"10px"}} icon={faPencil} onClick={() => this.openNumericFieldModal(
                              {"dataPath":this.props.dataPath+"/clock_minutes/set","currentValue":clockMinutePv,"unitOfMeasurement":"min. (UTC)", 
                              "objectName":this.props.customProps.objectName, "objectDescription":"Verification clock minute (UTC)", "min":0, "max":59,
                              "unitName":this.props.unitName, "userName":this.props.customProps.userName, "stepIncrement":1.0})}/>
                            </i>
                          </div>;
    }

    if(this.props.show){
      return(
      <div>
      <NumericEntryFieldModal auth_token={this.props.auth_token} colorTheme={this.props.colorTheme} show={this.state.showNumericFieldModal} close={this.closeNumericFieldModal} data={this.state.numericFieldModalData}/>

      <div style={modalStyle}>

        <div className="container">

          <div draggable="true" style={{cursor:"move"}} onDrag={(event) => this.dragWindow(event)} onDragEnd={this.resetIsDragging} className="row">
            <div className="col-sm-12">
              <button className="close-button" style={{backgroundColor:backgroundColor, float:"right", borderRadius:"5px", borderColor:textColor}} onClick={() => this.closeModal()}>
                <span style={{color:this.props.colorTheme() === "light" ? "black" : "white"}} aria-hidden="true">&times;</span>
              </button>
              <FontAwesomeIcon onClick={() => this.toggleEditUnlock()} className="float-start" size="2x" style={{cursor:"pointer", marginRight:"10px", color:textColor}} icon={editLockSymbol} />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <div className="text-center">
                <h5 style={{color:textColor}}>{this.props.unitName.toUpperCase()}</h5>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="text-center">
                <h5 style={{color:textColor}}>Verification</h5>
              </div>
            </div>
          </div>

          <div className="row my-2">
            <div className="col-1"></div>
            <div className="col-5">
              <div className="text-center">
                <button data-toggle="tooltip" style={{outlineColor:"#053d57"}} disabled={!this.state.editUnlocked} data-placement="top" title="Enable verification" onClick={() => this.setVerificationEnabled()} className={enableBtnClass}> Enabled </button>
              </div>
            </div>
            <div className="col-5">
              <div className="text-center">
                <button data-toggle="tooltip" style={{outlineColor:"#053d57"}} disabled={!this.state.editUnlocked} data-placement="top" title="Disable verification" onClick={() => this.setVerificationDisable()} className={disableBtnClass}> Disable </button>
              </div>
            </div>
            <div className="col-1"></div>
          </div>

          <div className="row my-2">
            <div className="col-1"></div>
            <div className="col-5">
              <div className="text-center">
               <button data-toggle="tooltip" style={{outlineColor:"#053d57"}} disabled={!this.state.editUnlocked} data-placement="top" title="Manual start verification" onClick={() => this.setVerificationManualStart()} className="btn btn-outline-secondary w-100"> Manual start </button>
              </div>
            </div>
            <div className="col-6"></div>
          </div>

          <div className="row my-1">
            <div className="col-sm-12">
              {lengthPvDiv}
            </div>
          </div>

          <div className="row my-1">
            <div className="col-sm-12">
              {internvalDaysDiv}
            </div>
          </div>

          <div className="row my-1">
            <div className="col-sm-12">
              {clockHourPvDiv}
            </div>
          </div>

           <div className="row my-1">
            <div className="col-sm-12">
              {clockMinutePvDiv}
            </div>
          </div>         

        </div> {/* container */}

      </div> 
      </div>
    )}
    else{
      return(
        <div></div>
      )
    }
  }

}
