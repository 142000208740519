import React from "react";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import {getServerUrl} from '../ApplicationNetworking'

export default class CreateNewDataSubModal extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        loading:false,
        error:false,
        positionOffsetX:window.innerWidth/2,
        positionOffsetY:window.innerHeight/2,
        modalWidth:0,
        modalHeight:0,
        isDragging:false,
        dragOffsets:null,
        mqttTopic:"",
        systemName:"",
        mqttQOS:1,
        responseText:"",
        dataSubCreated:false
      }
  }

  componentDidMount() {
    this.setModalDimensions();
    window.addEventListener('resize', this.setModalDimensions);
  }

  componentDidUpdate(previousProps) {
    // Reset the window when its closed so it will be centered at the screen once its opens again.
    if(previousProps.show && !this.props.show){
      this.resetWindowToCenter()
    }
  }

  setModalDimensions = () => {
    let width = "380px";
    if(window.innerWidth < 767){
      width = window.innerWidth*0.8;
    }
    let height = "450px";
    if(window.innerHeight < 767){
      height = window.innerHeight*0.8;
    }
    this.setState({ modalWidth: width, modalHeight:height});
    this.resetWindowToCenter();
  }

  resetWindowToCenter = () => {
    this.setState({positionOffsetX:window.innerWidth/2, positionOffsetY:window.innerHeight/2});
  }

  closeModal = () => {
    this.props.close(false);
    this.setState({dataSubCreated:false, systemName:"", responseText: "", mqttTopic:""});
  }


  dragWindow = (event) => {
    event.preventDefault();
    let cursorOffsetX = 0;
    let cursorOffsetY = 0;
    if(!this.state.dragCursorOffset){
      this.setState({dragCursorOffset:[event.clientX - this.state.positionOffsetX,event.clientY - this.state.positionOffsetY]});
      cursorOffsetX = event.clientX - this.state.positionOffsetX;
      cursorOffsetY = event.clientY - this.state.positionOffsetY;
    }
    else{
      cursorOffsetX = this.state.dragCursorOffset[0];
      cursorOffsetY = this.state.dragCursorOffset[1];  
    }
    if(event.clientX > 0 &&  event.clientY > 0){ 

      let newPosX = event.clientX - cursorOffsetX;
      // Limit X position inside window.
      if(newPosX > window.innerWidth - this.state.modalWidth/2){
        newPosX = window.innerWidth - this.state.modalWidth/2;
      }
      else if (newPosX < this.state.modalWidth/2){
        newPosX = this.state.modalWidth/2;
      }
      let newPosY = event.clientY - cursorOffsetY
      // Limit Y position inside window.
      if(newPosY > window.innerHeight - this.state.modalHeight/2){
        newPosY = window.innerHeight - this.state.modalHeight/2;
      }
      else if (newPosY < this.state.modalHeight/2){
        newPosY = this.state.modalHeight/2;
      }
      this.setState({positionOffsetX:newPosX, positionOffsetY:newPosY, isDragging:true});
    }
  }

  resetIsDragging = () =>{
    this.setState({isDragging:false, dragCursorOffset:null});
  }

  createNewDataSub = () => {
   if(this.state.mqttTopic.length > 0 && this.state.systemName.length > 0 && this.state.mqttQOS >= 0 && this.state.mqttQOS <= 2){
      this.setState({ isLoading:true, error:false, responseText: ""});
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          "mqtt_topic": this.state.mqttTopic,
          "system_name":this.state.systemName,
          "mqtt_qos":this.state.mqttQOS})};
      let url = getServerUrl() + "createdata?auth_token=" + this.props.auth_token;
      fetch(url, requestOptions)
       .then((response) => {
        if(response.ok){
          return response.json()
        }
        return Promise.reject(response);
       })
       .then((data) => {
          this.props.update();
          this.setState({responseText: "Data sub. created!",dataSubCreated:true});
          //setTimeout(this.closeModal(), 1000);
       })
       .catch((response) => {
        this.setState({isLoading: false, error:true, responseText: "Error updating value!"});
      });
   }
  }

  handleTopicChange = (event) => {
    let mqttTopic = event.target.value.toLowerCase();
    let systemName = mqttTopic.split("/")[0];
    this.setState({mqttTopic:mqttTopic,systemName:systemName});
  }


  /*
  handleSystemNameChange = (event) => {

    this.setState({systemName:event.target.value.toLowerCase()});
  }*/

  handleQOSChange = (event) => {
    this.setState({mqttQOS:event.target.value});
  }



  render() {

    let backgroundColor = this.props.colorTheme() === "light" ? "#f2f2f2" : "#323232";
    let inputFieldBackgroundColor = this.props.colorTheme() === "light" ? "#ffffff" : "#505050";
    let textColor = this.props.colorTheme() === "light" ? "black" : "rgb(200,200,200)";

    let modalStyle = {
        position:"absolute",
        height: this.state.modalHeight,
        width: this.state.modalWidth,
        left: this.state.positionOffsetX,
        top: this.state.positionOffsetY,
        transform: "translate(-50%, -50%)",
        backgroundColor: backgroundColor,
        border: this.props.colorTheme() === "light" ? "5px solid #053d57" : "2px solid #f2f2f2",
        borderRadius: "10px",
        zIndex:"2",
        padding:"5px"
    };

    let createNewDataSubButton = <></>;
    if(this.state.mqttTopic.length > 0 && this.state.systemName.length > 0 && this.state.mqttQOS >= 0 && this.state.mqttQOS <= 2){
      createNewDataSubButton = <button style={{color:textColor, borderColor:textColor}} type="button" className="btn btn-outline-secondary btn-block mb-4" onClick={() => this.createNewDataSub()}>Create</button>;
    }

    let responseText = <></>;
    if(this.state.dataSubCreated){
      responseText = <p className="text-success">{this.state.responseText}</p>
    }
    else{
      responseText = <p className="text-danger">{this.state.responseText}</p>
    }

    if(this.props.show){
      return(
      <div>

      <div style={modalStyle}>

        <div className="container">

          <div draggable="true" style={{cursor:"move"}} onDrag={(event) => this.dragWindow(event)} onDragEnd={this.resetIsDragging} className="row">
            <div className="col-sm-12">
              <button className="close-button" style={{backgroundColor:backgroundColor,float:"right", borderRadius:"5px", borderColor:textColor}} onClick={() => this.closeModal()}>
                <span style={{color:this.props.colorTheme() === "light" ? "black" : "white"}} aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>


        <div className="row">
          <div className="col-sm-12 text-center">
              <h5 style={{color:textColor}}> Create new data subscription </h5>
          </div>
        </div>

          <div className="row justify-content-md-center">
              <div className="col-md-auto">

                  <div className="row my-2">
                    <div className="text-center">
                      {responseText}
                    </div>
                  </div>

                  <div>
                    <form autoComplete="off">
                          <div className="form-outline mb-4">
                            <label style={{color:textColor}} className="form-label" htmlFor="createNewDataSubSystemName">System name:</label>
                            <input style={{backgroundColor:inputFieldBackgroundColor, color:textColor}} type="text" id="createNewDataSubSystemName" className="form-control" value={this.state.systemName} readOnly/>
                          </div>

                          <div className="form-outline mb-4">
                            <label style={{color:textColor}} className="form-label" htmlFor="createNewDataSubTopic">MQTT topic:</label>
                            <input style={{backgroundColor:inputFieldBackgroundColor, color:textColor}} type="text" id="createNewDataSubTopic" onChange={(event) => this.handleTopicChange(event)} className="form-control" value={this.state.mqttTopic}/>
                          </div>

                          <label style={{color:textColor}} className="form-label" htmlFor="createNewDataSubQOS">MQTT QoS:</label>
                          <select style={{backgroundColor: inputFieldBackgroundColor, color:textColor}} id="createNewDataSubQOS" value={this.state.mqttQOS} onChange={(event) => this.handleQOSChange(event)} className="form-select" aria-label="Default select example">
                            <option>Select role:</option>
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                          </select>

                          <div className="text-center my-3">
                            {createNewDataSubButton}
                          </div>
                      </form>
                    </div>

              </div>
            </div>     


          
        </div> {/* container */}

      </div> 
      </div>
    )}
    else{
      return(
        <div></div>
      )
    }
  }

}
