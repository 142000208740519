import React from "react";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

import CreateNewEventModal from '../modal/CreateNewEventModal'
import RestoreBackupConfigModal from '../modal/RestoreBackupConfigModal'

import {getServerUrl} from '../ApplicationNetworking'

export default class EventConfig extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        loading:false,
        error:false,
        showCreateNewAlarmModal:false,
        eventConfig:{},
        filter:"",
        backupEventConfigCompleted:false,
        showRestoreEventConfigModal:false,
      }
    }

componentDidMount() {
    this.getEventConfig();
  }

 getEventConfig = () => {
    if(this.props.auth_token){
     this.setState({ isLoading:true, error:false });
     let url = getServerUrl() + "alleventconfig?auth_token=" + this.props.auth_token;
     fetch(url)
       .then((response) => {
         if(!response.ok) throw new Error(response);
         else return response.json();
       })
       .then((data) => {
         this.setState({ isLoading:false, eventConfig:data});
       })
       .catch((error) => {
         this.setState({ isLoading: false, error:true  });
       });
    }
  }

  downloadEventConfigBackup = () => {
    if(this.state.eventConfig){
      try{
      let data = JSON.parse(this.state.eventConfig);
       const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
        JSON.stringify(data, undefined, 2) // pretty print, spacing level = 2
      )}`;
      const link = document.createElement("a");
      link.href = jsonString;
      link.download = "event-config.json";

      link.click();
      this.setState({backupEventConfigCompleted:true});
      }
      catch(error){
        // pass
      }
    }
  }

  openCreateNewEventModal = (dataPath) => {
    this.setState({showCreateNewEventModal:true});
  }

  closeCreateNewEventModal = () => {
    this.setState({showCreateNewEventModal:false});
  }

  openRestoreEventConfigModal = (dataPath) => {
    this.setState({showRestoreEventConfigModal:true});
  }

  closeRestoreEventConfigModal = () => {
    this.setState({showRestoreEventConfigModal:false});
  }

  confirmDeleteEvent = (mqttTopic, eventObjectName, eventDescription) => {
    let confirmDelete = window.confirm("Delete event: " + eventObjectName + " - " + eventDescription + "?");
    if(confirmDelete){
      this.deleteEvent(mqttTopic, eventObjectName, eventDescription);
    }
  }

  deleteEvent = (mqttTopic, eventObjectName, eventDescription) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        "mqtt_topic": mqttTopic,
        "event_object_name":eventObjectName,
        "event_description":eventDescription})};
    let url = getServerUrl() + "deleteeventconfig?auth_token=" + this.props.auth_token;
    fetch(url, requestOptions)
     .then((response) => {
      if(response.ok){
        return response.json()
      }
      return Promise.reject(response);
     })
     .then((data) => {
      this.getEventConfig();
        // pass
     })
     .catch((response) => {
      this.setState({isLoading: false, error:true});
    });
  }

  handleFilterChange = (event) => {
    this.setState({filter:event.target.value});
  }

  setVisibleBasedOnRole = (requiredRole) => {
    let role = this.props.userRole;
    if(role !== undefined){
      role = role.toLowerCase();
      requiredRole = requiredRole.toLowerCase();
      if(requiredRole === "administrator"){
        if(role === "administrator"){
          return "visible";
        }
        else{
          return "invisible";
        }
      }
      else if(requiredRole === "operator"){
        if(role === "administrator" || role === "operator"){
          return "visible";
        }
        else{
          return "invisible";
        }
      }
      else{
        return "visible";
      }
    }
    else{
      return "invisible";
    }
  }


  render() {

    let backgroundColor = this.props.colorTheme() === "light" ? "#f2f2f2" : "#323232";
    let textColor = this.props.colorTheme() === "light" ? "black" : "rgb(200,200,200)";
    let inputFieldBackgroundColor = this.props.colorTheme() === "light" ? "#ffffff" : "#505050";

    let eventConfig = {};
    try{
      eventConfig = JSON.parse(this.state.eventConfig);
    }
    catch(error){
      // pass
    }
    //console.log(eventConfig);
    let eventConfigListObjects = [];

    Object.keys(eventConfig).forEach(mqttTopic => {
      Object.keys(eventConfig[mqttTopic]).forEach(eventObjectName => {
        Object.keys(eventConfig[mqttTopic][eventObjectName]).forEach(eventDescription => {
          let includeObject = false;
          if(this.state.filter.length > 0){
            if(mqttTopic.includes(this.state.filter)){
              includeObject = true;
            }
          }
          else{
            includeObject = true;
          }

          let event = eventConfig[mqttTopic][eventObjectName][eventDescription];
          if(event !== undefined){
      
          if(includeObject){
            let eventObjectDescription = event.event_object_description;
            let systemName = event.system_name;
            let eventFromOperator = "" + event.event_from_operator; // convert bool to string
            let operatorName = event.operator_name;
            let eventValue = event.event_value;
            if(typeof eventValue === "boolean"){
              eventValue = eventValue ? "true" : "false";
            }
            let eventValueBitNumber = event.event_value_bit_number;
            let uniqueKey = mqttTopic + eventObjectName + eventDescription;
            let object = <tr key={uniqueKey}>
                           <td style={{color:textColor}}>{systemName}</td>
                           <td style={{color:textColor}}>{eventObjectName}</td>
                           <td style={{color:textColor}}>{eventObjectDescription}</td>
                           <td style={{color:textColor}}>{eventDescription}</td>
                           <td style={{color:textColor}}>{operatorName}</td>
                           <td style={{color:textColor}}>{eventFromOperator}</td>
                           <td style={{color:textColor}}>{mqttTopic}</td>
                           <td style={{color:textColor}}>{eventValue}</td>
                           <td style={{color:textColor}}>{eventValueBitNumber}</td>
                           <td className="text-center"> <FontAwesomeIcon className={this.setVisibleBasedOnRole("administrator")} onClick={() => this.confirmDeleteEvent(mqttTopic, eventObjectName, eventDescription)}style={{marginRight:"10px", color:textColor,cursor:"pointer"}} size="lg" icon={faTrash}/> </td>
                        </tr>
            eventConfigListObjects.push(object)         
          }
          }

        })
      })
    })
    
    return(
      <div>

      <CreateNewEventModal colorTheme={this.props.colorTheme} update={this.getEventConfig} auth_token={this.props.auth_token} show={this.state.showCreateNewEventModal} close={this.closeCreateNewEventModal} />
      <RestoreBackupConfigModal colorTheme={this.props.colorTheme} title="Restore event configuration:" restAPIURL="eventconfigrestorefrombackup" update={this.getEventConfig} auth_token={this.props.auth_token} show={this.state.showRestoreEventConfigModal} close={this.closeRestoreEventConfigModal} />

        <div className="container-fluid">
          <div className="row">
            <div className="col-12 text-center">
                <h5 style={{color:textColor}}> Event configuration </h5>
            </div>
          </div>

          <div className="row my-1">
            <div className="col-6">
                <label style={{color:textColor}} className="form-label" htmlFor="eventConfigFilterInput">Filter:</label>
                <input style={{backgroundColor:inputFieldBackgroundColor, color:textColor}} autoComplete="off" placeholder="Type to filter based on mqtt topic.." type="text" id="eventConfigFilterInput" onChange={(event) => this.handleFilterChange(event)} className="form-control w-50" value={this.state.filter}/>
            </div>

            <div className="col-6">
              <div className="btn-group float-end">
                <button type="button" style={{color:textColor, borderColor:textColor}} onClick={() => this.openRestoreEventConfigModal()} className={this.state.backupEventConfigCompleted ? "btn btn-outline-secondary " + this.setVisibleBasedOnRole("administrator")  : "btn btn-outline-dark invisible"}>Restore from backup</button>
                <button type="button" style={{color:textColor, borderColor:textColor}} onClick={() => this.downloadEventConfigBackup()} className={"btn btn-outline-secondary " + this.setVisibleBasedOnRole("administrator")}>Backup event config.</button>
                <button type="button" style={{color:textColor, borderColor:textColor}} onClick={() => this.openCreateNewEventModal()} className={"btn btn-outline-secondary " + this.setVisibleBasedOnRole("administrator")}>Create new event</button>
              </div>
            </div>
          </div>

          <div className="table-responsive" style={{maxHeight:"50vh", border:"3px solid #787878", borderRadius:"5px"}}>
            <table style={{maxHeight:"50px"}} className="table table-bordered table-hover table-condensed">
              <thead style={{position:"sticky", "top":0, backgroundColor:backgroundColor}}>
                <tr >
                  <th style={{color:textColor}} scope="col">System name</th>
                  <th style={{color:textColor}} scope="col">Object name</th>
                  <th style={{color:textColor}} scope="col">Object description</th>
                  <th style={{color:textColor}} scope="col">Event description</th>
                  <th style={{color:textColor}} scope="col">Operator name</th>
                  <th style={{color:textColor}} scope="col">Event from operator</th>
                  <th style={{color:textColor}} scope="col">MQTT topic</th>
                  <th style={{color:textColor}} scope="col">Event value</th>
                  <th style={{color:textColor}} scope="col">Event value bit no</th>
                  <th style={{color:textColor}} scope="col"></th>
                </tr>
              </thead>
              <tbody>

              {eventConfigListObjects}

              </tbody>
            </table>
          </div>


        </div>
        

      </div>
    )
  }

}
