import React from "react";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import {getServerUrl} from '../ApplicationNetworking'

export default class Users extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        loading:false,
        error:false,
        sessions:[]
      }
    }

componentDidMount() {
    this.getSessions();
  }

 getSessions = () => {
    if(this.props.auth_token){
     this.setState({ isLoading:true, error:false });
     let url = getServerUrl() + "sessions?auth_token=" + this.props.auth_token;
     fetch(url)
       .then((response) => {
         if(!response.ok) throw new Error(response);
         else return response.json();
       })
       .then((data) => {
         this.setState({ isLoading:false, sessions:data});
       })
       .catch((error) => {
         this.setState({ isLoading: false, error:true  });
       });
    }
  }


  convertSecondToHHMMSS = (totalSeconds) => {
    let retValue = "";
    if(!isNaN(totalSeconds)){
      totalSeconds = parseInt(totalSeconds);
      let hours = Math.floor(totalSeconds / 3600);
      totalSeconds %= 3600;
      let minutes = Math.floor(totalSeconds / 60);
      let seconds = totalSeconds % 60;
      if(hours > 0){
        if(hours < 10){
          retValue = "0" + hours + " h "
        }
        else{
          retValue = hours + " h "
        }
        
      }
      if(minutes > 0){
        if(minutes < 10){
          retValue += "0" + minutes + " m "
        }
        else{
          retValue += minutes + " m "
        }
      }
      if(seconds > 0){
        if(seconds < 10){
          retValue += "0" + seconds + " s "
        }
        else{
          retValue += seconds + " s "
        }
      }
    }
    return retValue

  }

  confirmTerminateSession = (terminate_token) => {
    let confirmDelete = window.confirm("Terminate session?");
    if(confirmDelete){
      this.terminateSession(terminate_token);
    }
  }

  terminateButtonClassName = (terminate_token) => {
    if(terminate_token === this.props.auth_token){
      return "btn btn-danger btn-sm invisible"
    }
    else{
      return "btn btn-danger btn-sm"
    }
  }

  terminateSession = (terminate_token) => {
    if(this.props.auth_token){
     this.setState({ isLoading:true, error:false });
     let url = getServerUrl() + "terminatesession?auth_token=" + this.props.auth_token + "&terminate_token=" + terminate_token;
     fetch(url)
       .then((response) => {
         if(!response.ok) throw new Error(response);
         else return response.json();
       })
       .then((data) => {
          this.getSessions() // Just update the session list after terminate a session.
       })
       .catch((error) => {
         this.setState({ isLoading: false, error:true  });
       });
    }
  }

  concatString = (value1, value2) => {
    return value1 + value2
  }

  render() {

    let backgroundColor = this.props.colorTheme() === "light" ? "#f2f2f2" : "#323232";
    let textColor = this.props.colorTheme() === "light" ? "black" : "rgb(200,200,200)";

    let sessionsListDiv = <></>;

    sessionsListDiv = this.state.sessions.map((jsonData) =>{
      let session = <tr key={JSON.stringify(jsonData.token)}>
                    <th style={{color:textColor}}>{jsonData.username}</th>
                    <td style={{color:textColor}}>{jsonData.role}</td>
                    <td style={{color:textColor}}>{this.convertSecondToHHMMSS(jsonData.time_logged_in)}</td>
                    <td style={{color:textColor}}>{jsonData.metadata.screensize}</td>
                    <td style={{color:textColor}}>{jsonData.metadata.address}</td>
                    <td style={{color:textColor}}>{jsonData.metadata.os}</td>
                    <td style={{color:textColor}}><button className={this.terminateButtonClassName(jsonData.token)} data-toggle="confirmation" onClick={() => this.confirmTerminateSession(jsonData.token)}>Terminate</button></td>
                  </tr>
      return session;
    });

    return(
      <div style={{height:"100%",width:"100%"}}>

          <div className="row">
            <div className="col-12 text-center">
                <h5 style={{color:textColor}}> Sessions </h5>
            </div>
          </div>

          <div className="table-responsive" style={{border:"3px solid #787878", borderRadius:"5px", maxHeight:"60vh"}}>
            <table style={{maxHeight:"50px"}} className="table table-bordered table-hover table-condensed">
              <thead style={{position:"sticky", "top":0, backgroundColor:backgroundColor}}>
                <tr>
                  <th style={{color:textColor}} scope="col">Username:</th>
                  <th style={{color:textColor}} scope="col">Role:</th>
                  <th style={{color:textColor}} scope="col">Timed Logged In:</th>
                  <th style={{color:textColor}} scope="col">Sceensize:</th>
                  <th style={{color:textColor}} scope="col">Address:</th>
                  <th style={{color:textColor}} scope="col">OS:</th>
                  <th style={{color:textColor}} scope="col">Terminate:</th>
                </tr>
              </thead>
              <tbody>

                {sessionsListDiv}

              </tbody>
            </table>
          </div>

      </div>
    )
  }

}
