import React from "react";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

export default class Grafana extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        loading:false,
        error:false
      }
    }


  render() {

    let grafanaAddress = "http://" + window.location.hostname + ":3000";
    if(window.location.href.includes("searas.no")){
      grafanaAddress = "http://"+ window.location.hostname + "/grafana";
    }

    return(

      <div style={{width:"inherit",height:"inherit"}}>
        <iframe style={{width:"100%", height:"100%"}} src={grafanaAddress} title="Grafana"></iframe>
      </div>
    )

    }

}