
import React from "react";


export default class SvgAnalog extends React.Component {

	prohibitPopUpFunc = () => {
		return false;
	}

	render(){

		let data = {};;
		if(this.props.data !== undefined){
			data = this.props.data;
		}

		let alarmRegister = 0;
		let signalFault = false;
		if(data.hasOwnProperty(this.props.dataPath + "/alarm_register")){
			alarmRegister = data[this.props.dataPath + "/alarm_register"].value;
			signalFault = (alarmRegister & Math.pow(2,4)) === Math.pow(2,4); 
		}
		
		let pv = "No data!";
		let unit_of_measurement = "";
		if(!signalFault){
			if(data.hasOwnProperty(this.props.dataPath + "/pv_filtered")){
				pv = data[this.props.dataPath + "/pv_filtered"].value;
				unit_of_measurement = data[this.props.dataPath + "/pv_filtered"].unit_of_measurement;
				if(unit_of_measurement === "deg.c"){
					unit_of_measurement = "°C";
				}
				else if(unit_of_measurement === "meter"){
					unit_of_measurement = "m";
				}
				let decimals = 2;
				if(this.props.decimals !== undefined){
					decimals = this.props.decimals;
				}
				pv =  parseFloat(parseFloat(pv).toFixed(decimals)) + " " + unit_of_measurement;
			}
		}

		let alarmText = "";
		if(alarmRegister & Math.pow(2,0)){
			alarmText = "HH"
		}
		if(alarmRegister & Math.pow(2,1)){
			if(alarmText.length > 0){
				alarmText += ",H"
			}
			else{
				alarmText = "H"
			}
			
		}
		if(alarmRegister & Math.pow(2,2)){
			if(alarmText.length > 0){
				alarmText += ",L"
			}
			else{
				alarmText = "L"
			}
			
		}
		if(alarmRegister & Math.pow(2,3)){
			if(alarmText.length > 0){
				alarmText += ",LL"
			}
			else{
				alarmText = "LL"
			}
			
		}
		if(alarmRegister & Math.pow(2,4)){
			alarmText = "SF"	
		}

		let customsProps = {};
		if(this.props.objectName !== undefined){
			customsProps["objectName"] = this.props.objectName;
		}
		if(this.props.objectDescription !== undefined){
			customsProps["objectDescription"] = this.props.objectDescription;
		}
		if(this.props.userName !== undefined){
			customsProps["userName"] = this.props.userName;
		}
		if(this.props.pvMin !== undefined){
			customsProps["pvMin"] = this.props.pvMin;
		}
		if(this.props.pvMax !== undefined){
			customsProps["pvMax"] = this.props.pvMax;
		}

		let modalFunction = this.prohibitPopUpFunc;
		if(typeof this.props.openModal === "function"){
			modalFunction = () => this.props.openModal(this.props.dataPath, customsProps);
		}

		let alarmTextPosX = this.props.x + 30;
		if(this.props.alarmTextPosX !== undefined){
			alarmTextPosX = this.props.x + parseFloat(this.props.alarmTextPosX);
		}
		let alarmTextPosY = this.props.y + 6;
		if(this.props.alarmTextPosY !== undefined){
			alarmTextPosY = this.props.y + parseFloat(this.props.alarmTextPosY);
		}
		let alarmTextAlignmentBaseline = "start";
		if(this.props.alarmTextAlignmentBaseline !== undefined){
			alarmTextAlignmentBaseline = this.props.alarmTextAlignmentBaseline;
		}

		let alarmTextStyle = {
			fontSize:"24",
			alignmentBaseline:alarmTextAlignmentBaseline,
			textAnchor:alarmTextAlignmentBaseline
		}

		let titleTextColor = this.props.colorTheme() === "light" ? "black" : "rgb(200,200,200)";
    	let textColor = this.props.colorTheme() === "light" ? "black" : "rgb(200,200,200)";

    	let backgroundColor = this.props.colorTheme() === "light" ? "#f2f2f2" : "#323232";
    	let strokeColor = this.props.colorTheme() === "light" ? "rgb(0,0,0)" : "rgb(200,200,200)";
		if(alarmRegister > 0){
			let alarmRegisterBit0Active = (alarmRegister & Math.pow(2,0)) === Math.pow(2,0);
			let alarmRegisterBit1Active = (alarmRegister & Math.pow(2,1)) === Math.pow(2,1);
			let alarmRegisterBit2Active = (alarmRegister & Math.pow(2,2)) === Math.pow(2,2);
			let alarmRegisterBit3Active = (alarmRegister & Math.pow(2,3)) === Math.pow(2,3);
			let alarmRegisterBit4Active = (alarmRegister & Math.pow(2,4)) === Math.pow(2,4);
			if(alarmRegisterBit0Active || alarmRegisterBit3Active || alarmRegisterBit4Active){
				backgroundColor = "rgb(213,0,0)";
				textColor = "black";
			}
			else if(alarmRegisterBit1Active || alarmRegisterBit2Active){
				backgroundColor = "rgb(255,255,0)";
				textColor = "black";
			}
		}

		// Mode select, 0 = Local, 1 = Manual, 2 = Remote
		let modeSelect = 0;
		let modeSelectText = <></>;
		if(data.hasOwnProperty(this.props.dataPath + "/mode_select")){
			modeSelect = data[this.props.dataPath + "/mode_select"].value;
			if(modeSelect > 0){
				let modeSelectTextSize = 16;
				if(this.props.modeSelectTextSize !== undefined){
					modeSelectTextSize = this.props.modeSelectTextSize;
				}
				let modeSelectStyleText = {
					fontSize:modeSelectTextSize,
					alignmentBaseline:"start",
					textAnchor:"start"
				}
				let modeSelectPosX = this.props.x + 25;
				if(this.props.modeSelectPosX !== undefined){
					modeSelectPosX = this.props.x + parseFloat(this.props.modeSelectPosX);
				}
				let modeSelectPosY = this.props.y + 25;
				if(this.props.modeSelectPosY !== undefined){
					modeSelectPosY = this.props.y + parseFloat(this.props.modeSelectPosY);
				}
				let textValue = "";
				if(modeSelect === 1){
					textValue = "Manual";
				}
				else if(modeSelect === 2){
					textValue = "Remote";
				}
				modeSelectText = <text fill={titleTextColor} x={modeSelectPosX} y={modeSelectPosY} style={modeSelectStyleText} >{textValue}</text>
			}
			
		}

		let instrument = <></>;
		if(this.props.showInstrument){
			let instrumentTagFontSize = 24;
			if(this.props.instrumentTag !== undefined){
				if(this.props.instrumentTag.length > 2){
					instrumentTagFontSize = 20;
				}
			}
			let instrumentStyleText = {
				fontSize:instrumentTagFontSize,
				alignmentBaseline:"middle",
				textAnchor:"middle"
			};
			instrument = <>
						 <circle fill={backgroundColor} stroke={strokeColor} cx={this.props.x} cy={this.props.y - 4} r="25" />
						 <text fill={textColor} x={this.props.x} y={this.props.y - 1} style={instrumentStyleText} >{this.props.instrumentTag}</text>
						 </>;
		};

		return(
			<>
				<text fill={titleTextColor} x={this.props.x} y={this.props.y-94} style={{fontSize:"24",alignmentBaseline:"middle",textAnchor:"middle"}} >{this.props.objectName}</text>
				<rect fill={backgroundColor} stroke={strokeColor} x={this.props.x-60} y={this.props.y-80} width="120" height="45" ry="10" />
				<text fill={textColor} x={this.props.x} y={this.props.y-55} style={{fontSize:"22",alignmentBaseline:"middle",textAnchor:"middle"}} >{pv}</text>
				<text fill="rgb(213,0,0)" x={alarmTextPosX} y={alarmTextPosY} style={alarmTextStyle} >{alarmText}</text>
				{instrument}
				{modeSelectText}
				<rect onClick={modalFunction} fill="transparent" stroke="transparent" x={this.props.x-75} y={this.props.y-110} width="150" height="140" ry="10" />
			</>
		) 
	}
}
	
          