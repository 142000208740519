
import React from "react";


export default class SvgValve extends React.Component {

	prohibitPopUpFunc = () => {
		return false;
	}

	getPolyLineValve = (direction) => {
		let dir = "right";
		if(direction !== undefined){
			dir = direction.toLowerCase();
		}
		if(dir === "up"){
			let valveUp =  (this.props.x - 7.5) + "," + (this.props.y - 12.5);
			valveUp += " " + (this.props.x - 17.5) + "," + (this.props.y - 35);
			valveUp += " " + (this.props.x + 17.5) + "," + (this.props.y - 35);
			valveUp += " " + (this.props.x + 7.5) + "," + (this.props.y - 12.5);
			valveUp += " " + (this.props.x - 7.5) + "," + (this.props.y - 12.5);
			return valveUp;
		}
		else if(dir === "down"){
			let valveDown =  (this.props.x - 7.5) + "," + (this.props.y + 12.5);
			valveDown += " " + (this.props.x - 17.5) + "," + (this.props.y + 35);
			valveDown += " " + (this.props.x + 17.5) + "," + (this.props.y + 35);
			valveDown += " " + (this.props.x + 7.5) + "," + (this.props.y + 12.5);
			valveDown += " " + (this.props.x - 7.5) + "," + (this.props.y + 12.5);
			return valveDown;
		}
		else if(dir === "left"){
			let valveLeft =  (this.props.x - 12.5) + "," + (this.props.y - 7.5);
			valveLeft += " " + (this.props.x - 35) + "," + (this.props.y - 17.5);
			valveLeft += " " + (this.props.x - 35) + "," + (this.props.y + 17.5);
			valveLeft += " " + (this.props.x - 12.5) + "," + (this.props.y + 7.5);
			valveLeft += " " + (this.props.x - 12.5) + "," + (this.props.y - 7.5);
			return valveLeft;
		}
		else{
			let valveRight =  (this.props.x + 12.5) + "," + (this.props.y - 7.5);
			valveRight += " " + (this.props.x + 35) + "," + (this.props.y - 17.5);
			valveRight += " " + (this.props.x + 35) + "," + (this.props.y + 17.5);
			valveRight += " " + (this.props.x + 12.5) + "," + (this.props.y + 7.5);
			valveRight += " " + (this.props.x + 12.5) + "," + (this.props.y - 7.5);
			return valveRight;
		}
	}

	render(){

		let data = {};;
		if(this.props.data !== undefined){
			data = this.props.data;
		}


		let statusRegister = 0;
		if(data.hasOwnProperty(this.props.dataPath + "/status_register")){
			statusRegister = data[this.props.dataPath + "/status_register"].value;
		}

		let alarmRegister = 0;
		if(data.hasOwnProperty(this.props.dataPath + "/alarm_register")){
			alarmRegister = data[this.props.dataPath + "/alarm_register"].value;
		}

		let commandoRegister = 0;
		if(data.hasOwnProperty(this.props.dataPath + "/commando_register")){
			commandoRegister = data[this.props.dataPath + "/commando_register"].value;
		}		

		let openColor = "rgb(255,255,255)";
		let isOpen = false;
		if(statusRegister & Math.pow(2,0)){
			openColor = "rgb(0,213,0)";
			isOpen = true;
		}
		let closeColor = "rgb(213,213,213)"
		let isClosed = false;
		if(statusRegister & Math.pow(2,1)){
			closeColor = "rgb(0,213,0)";
			isClosed = true;
		}

		let openNorCloseColor = "rgb(255,255,0)";

		/* Title text */
		let titlePositionX = this.props.x - 40;
		if(this.props.titlePositionX){
			titlePositionX = this.props.x - this.props.titlePositionX;
		}
		let titlePositionY = this.props.y - 55;
		if(this.props.titlePositionY){
			titlePositionY = this.props.y - this.props.titlePositionY;
		}
		let titleFontSize = 24;
		if(this.props.titleFontSize){
			titleFontSize = this.props.titleFontSize;
		}
		let titleStyle = {
			fontSize:String(titleFontSize),
			alignmentBaseline:"middle",
			textAnchor:"middle"
		};
		let titleText = this.props.objectName;

		/* Mode text, e.g. M for manual, LM for local manual etc */
		let modePositionX = this.props.x + 35;
		if(this.props.modePositionX){
			modePositionX = this.props.modePositionX;
		}
		let modePositionY = this.props.y + 40;
		if(this.props.modePositionY){
			modePositionY = this.props.modePositionY;
		}
		let modeFontSize = 24;
		if(this.props.modeFontSize){
			modeFontSize = this.props.modeFontSize;
		}
		let modeStyle = {
			fontSize:String(modeFontSize),
			alignmentBaseline:"middle",
			textAnchor:"middle"
		};
		let modeText = "";
		if(!(commandoRegister & Math.pow(2,0))){
			modeText = "M";
		}
		/* Feedback speed text */
		let feedbackSpeedFontSize = 20;
		if(this.props.feedbackSpeedFontSize){
			feedbackSpeedFontSize = this.props.feedbackSpeedFontSize;
		}
		let feedbackSpeedFontStyle = {
			fontSize:String(feedbackSpeedFontSize),
			alignmentBaseline:"middle",
			textAnchor:"middle"
		};
		let feedbackSpeedPositionX = this.props.x - 25;
		if(this.props.feedbackSpeedPositionX){
			feedbackSpeedPositionX = this.props.feedbackSpeedPositionX;
		}
		let feedbackSpeedPositionY = this.props.y + 58;
		if(this.props.feedbackSpeedPositionY){
			feedbackSpeedPositionY = this.props.feedbackSpeedPositionY;
		}
		
		let feedbackSpeedText = <></>;
		if(data.hasOwnProperty(this.props.dataPath + "/feedback_speed")){
			let feedbackSpeed = parseFloat(data[this.props.dataPath + "/feedback_speed"].value).toFixed(1);
			feedbackSpeedText = <text x={feedbackSpeedPositionX} y={feedbackSpeedPositionY} style={feedbackSpeedFontStyle} >{feedbackSpeed} %</text>;
		}

		let customsProps = {};
		if(this.props.objectName !== undefined){
			customsProps["objectName"] = this.props.objectName;
		}
		if(this.props.objectDescription !== undefined){
			customsProps["objectDescription"] = this.props.objectDescription;
		}
		if(this.props.userName !== undefined){
			customsProps["userName"] = this.props.userName;
		}
		if(this.props.showAutoPositionSetpoint !== undefined){
			customsProps["showAutoPositionSetpoint"] = this.props.showAutoPositionSetpoint;
		}
		if(this.props.showManualPositionSetpoint !== undefined){
			customsProps["showManualPositionSetpoint"] = this.props.showManualPositionSetpoint;
		}
		let valve1OpenColor = openColor;
		let valve1CloseColor = closeColor;
		let valve1PolyLine = <></>;
		if(this.props.valve1CloseColor !== undefined){
			valve1CloseColor = this.props.valve1CloseColor;
		}
		if(this.props.valve1OpenColor !== undefined){
			valve1OpenColor = this.props.valve1OpenColor;
		}
		let valve2OpenColor = openColor;
		let valve2CloseColor = closeColor;
		let valve2PolyLine = <></>;
		if(this.props.valve2CloseColor !== undefined){
			valve2CloseColor = this.props.valve2CloseColor;
		}
		if(this.props.valve2OpenColor !== undefined){
			valve2OpenColor = this.props.valve2OpenColor;
		}
		let valve3OpenColor = openColor;
		let valve3CloseColor = closeColor;
		let valve3PolyLine = <></>;
		if(this.props.valve3CloseColor !== undefined){
			valve3CloseColor = this.props.valve3CloseColor;

		}
		if(this.props.valve3OpenColor !== undefined){
			valve3OpenColor = this.props.valve3OpenColor;
	
		}
		let valve1Color = openNorCloseColor;
		let valve2Color = openNorCloseColor;
		let valve3Color = openNorCloseColor;
		if(isOpen){
			valve1Color = valve1OpenColor;
			valve2Color = valve2OpenColor;
			valve3Color = valve3OpenColor;
		}
		if(isClosed){
			valve1Color = valve1CloseColor;
			valve2Color = valve2CloseColor;
			valve3Color = valve3CloseColor;
		}

    	let textColor = this.props.colorTheme() === "light" ? "black" : "rgb(200,200,200)";
    	let backgroundColor = this.props.colorTheme() === "light" ? "rgb(213,213,213)" : "#323232";
    	let strokeColor = this.props.colorTheme() === "light" ? "rgb(0,0,0)" : "rgb(200,200,200)";
		if(alarmRegister > 0){
			backgroundColor = "rgb(213,0,0)"
		}

		if(this.props.valve1Direction !== undefined){
			valve1PolyLine = <polyline fill={valve1Color} stroke={strokeColor} strokeWidth="1" points={this.getPolyLineValve(this.props.valve1Direction)} />
		}
		if(this.props.valve2Direction !== undefined){
			valve2PolyLine = <polyline fill={valve2Color} stroke={strokeColor} strokeWidth="1" points={this.getPolyLineValve(this.props.valve2Direction)} />
		}
		if(this.props.valve3Direction !== undefined){
			valve3PolyLine = <polyline fill={valve3Color} stroke={strokeColor} strokeWidth="1" points={this.getPolyLineValve(this.props.valve3Direction)} />
		}

		let modalFunction = this.prohibitPopUpFunc;
		if(typeof this.props.openModal === "function"){
			modalFunction = () => this.props.openModal(this.props.dataPath, customsProps);
		}



		return(
			<>
			<rect fill={backgroundColor} stroke={strokeColor} x={this.props.x - 12.5} y={this.props.y - 12.5} width="25" height="25" />
			{valve1PolyLine}
			{valve2PolyLine}
			{valve3PolyLine}
			<text fill={textColor} x={titlePositionX} y={titlePositionY} style={titleStyle} >{titleText}</text>
			<text fill={textColor} x={modePositionX} y={modePositionY} style={modeStyle} >{modeText}</text>
			{feedbackSpeedText}
			<rect onClick={modalFunction} fill="transparent" stroke="transparent" x={this.props.x-60} y={this.props.y-80} width="120" height="160" ry="10" />
			</>
		) 
	}
}
	
          